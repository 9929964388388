<template>
    <b-container>
        <b-card class="text-center">
            <h2>
                <font-awesome-icon
                    icon="clipboard"
                    class="mr-2"
                ></font-awesome-icon>
                Sign-ups
            </h2>
            <div class="mt-3">
                <button
                    class="sc-btn pill"
                    @click="addSignup()"
                >
                    <font-awesome-icon icon="plus-circle" class="mr-1"></font-awesome-icon>
                    Add Sign-up
                </button>
            </div>
        </b-card>
        <base-list
            :key="refreshCount"
            class="mt-2"
            list-url="/api/sign-ups/admin/list"
            delete-url="/api/forms/admin/remove" 
            itemsKey="signups"
            empty-text="No signups to display."
            
        >
            <template #content="{ item: signup }">
                <router-link
                    :to="{ name: 'signup_settings', params: { id: signup.id } }"       
                >
                    <h5>
                        <em v-if="!signup.name.length">No title.</em>
                        <span v-else>{{ signup.name }}</span>
                    </h5>
                </router-link>
                <h6 v-if="signup.expires" class="text-muted">
                    <i>Sign-up available until {{ $moment(signup.expires).local().format("LLLL") }}.</i>
                </h6>
                <p>{{ signup.description || "No description." }}</p>
                <p>
                    <b-badge variant="primary">
                        {{ signup.member_count }}
                    </b-badge> signed up<span v-if="signup.max_members"> (Maximum: {{ signup.max_members }})</span>.
                </p>
            </template>
            <template #actions="{ item: signup }">
                <div
                    class="sc-tooltip"
                >
                    <router-link
                        
                        is="b-button"
                        variant="link"
                        class="mr-2"
                        :to="{ name: 'signup_settings', params: { id: signup.id } }"
                    >
                        <font-awesome-icon icon="cog"></font-awesome-icon>
                    </router-link>
                    <span class="tooltiptext">Settings</span>
                </div>
                <div
                    class="sc-tooltip"
                >
                    <router-link
                        is="b-button"
                        variant="link"
                        class="mr-2"
                        :to="{ name: 'signup_list', params: { id: signup.id } }"
                    >
                        <font-awesome-icon icon="table"></font-awesome-icon>
                        <!--<b-badge
                            variant="danger"
                            class="ml-2"
                        >
                            {{ form.response_count }}
                        </b-badge>-->
                    </router-link>
                    <span class="tooltiptext">Responses</span>
                </div>
                <div
                    class="sc-tooltip"
                >
                    <b-button
                        variant="link"
                        class="mr-2"
                        @click="cloneSignup(signup.id)"
                    >
                        <font-awesome-icon icon="clone"></font-awesome-icon>
                    </b-button>
                    <span class="tooltiptext">Clone</span>
                </div>
            </template>
        </base-list>
    </b-container>
</template>
<script>
import BaseList from "@/components/ui/BaseList.vue"

export default {
    components: { BaseList },
    data () {
        return {
            busy    : false,
            refreshCount: 0
        };
    },
    methods: {
        addSignup() {
            return this.$api.post("/api/sign-ups/admin/create").then((response) => {
                this.$bvToast.toast(response.data.message, {
                    title           : "Notification",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
                this.$router.push({ name: "signup_settings", params: { id: response.data.signup_id } });
                //this.$root.$emit("bv::refresh::table", "signup-table");
            });
        },
        confirmDelete(signup) {
            console.log(arguments);
            this.$bvModal.msgBoxConfirm("Please confirm that you want to delete the sign-up '" + signup.name + ".'", {
                title           : "Please Confirm",
                size            : "sm",
                buttonSize      : "sm",
                okVariant       : "danger",
                okTitle         : "Delete",
                cancelTitle     : "Cancel",
                footerClass     : "p-2",
                hideHeaderClose : false,
                centered        : true
            })
            .then(deleteConfirmed => {
                if (deleteConfirmed) {
                    this.deleteSignup(signup.id);
                }
            })
            .catch(err => {
                console.log("Error:", err);
            });
        },
        deleteSignup(signup_id) {
            this.$api.get(`/api/sign-ups/admin/${signup_id}/remove`).then(response => {
                this.$bvToast.toast(response.data.message, {
                    title           : "Notification",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
                this.refreshCount++;
                // this.$root.$emit("bv::refresh::table", "signup-table");
            });
        },
        cloneSignup(signup_id) {
            this.$api.get(`/api/sign-ups/admin/${signup_id}/clone`).then(response => {
                this.$bvToast.toast(response.data.message, {
                    title           : "Notification",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
                this.refreshCount++;
                // this.$root.$emit("bv::refresh::table", "signup-table");
            });
        }
    }
}
</script>